import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { CoreModule } from 'ngx-myia-core';
import { ReduxModule } from 'ngx-myia-redux';
import { HttpErrorInterceptor } from './httpErrorInterceptor';
import { JWTInterceptor } from './JWTInterceptor';

@NgModule({
    imports: [CoreModule, ReduxModule, HttpClientModule],
    providers: [
        {
            provide: HTTP_INTERCEPTORS,
            useClass: JWTInterceptor,
            multi: true
        },
        {
            provide: HTTP_INTERCEPTORS,
            useClass: HttpErrorInterceptor,
            multi: true
        }
    ]
})
export class HttpModule {}
