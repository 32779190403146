import { HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { IAuthState } from './authReduxState.interface';
import { ReduxStore } from 'ngx-myia-redux';

@Injectable({ providedIn: 'root' })
export class JWTService {
    // temporary solution how to skip authorization header
    // see https://github.com/angular/angular/issues/18155
    private _noAuthUrls: Array<string> = [];

    constructor(private _store: ReduxStore) {}

    applyJWTHeaders(req: HttpRequest<any>): HttpRequest<any> {
        if (this._noAuthUrls.indexOf(req.url) === -1) {
            const authState: IAuthState = this._store.getState('auth');
            let token;
            if (authState) {
                const storedToken = authState.token || '';
                if (storedToken) {
                    token = `bearer ${storedToken}`;
                }
            }
            if (token) {
                req = req.clone({
                    setHeaders: {
                        Authorization: token
                    }
                });
            }
        }
        return req;
    }

    skipAuthorization(url: string) {
        if (this._noAuthUrls.indexOf(url) === -1) {
            this._noAuthUrls.push(url);
        }
    }
}
