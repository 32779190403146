import { Observable } from 'rxjs';

import {
    HttpEvent,
    HttpHandler,
    HttpInterceptor,
    HttpRequest
} from '@angular/common/http';
import { Injectable } from '@angular/core';

import { JWTService } from './JWTService';

@Injectable()
export class JWTInterceptor implements HttpInterceptor {
    constructor(private _jwtService: JWTService) {}

    intercept(
        req: HttpRequest<any>,
        next: HttpHandler
    ): Observable<HttpEvent<any>> {
        req = this._jwtService.applyJWTHeaders(req);
        return next.handle(req);
    }
}
