import { Observable, throwError } from 'rxjs';
import { catchError, mergeMap } from 'rxjs/operators';

import {
    HttpErrorResponse,
    HttpEvent,
    HttpHandler,
    HttpInterceptor,
    HttpRequest,
    HttpResponse
} from '@angular/common/http';
import { Injectable, Injector } from '@angular/core';
import { Logger } from 'ngx-myia-core';

import { BackendService } from './backendService';
import { ITOKEN_SERVICE } from './httpModuleTokens';
import { ITokenService } from './ITokenService';
import { JWTService } from './JWTService';

@Injectable()
export class HttpErrorInterceptor implements HttpInterceptor {
    constructor(
        private _injector: Injector,
        private _jwtService: JWTService,
        private _logger: Logger
    ) {}

    intercept(
        req: HttpRequest<any>,
        next: HttpHandler
    ): Observable<HttpEvent<any>> {
        return next.handle(req).pipe(
            catchError(err => {
                if (err instanceof HttpErrorResponse) {
                    const msg = `HttpResponse status: ${err.status}`;
                    this._logger.error(msg);
                    if (err.status === 403) {
                        const backendService = this._injector.get(
                            BackendService
                        );
                        backendService.accessDenied.emit();
                    }
                    if (err.status === 401) {
                        // JWT expired
                        const tokenService: ITokenService = this._injector.get(
                            ITOKEN_SERVICE,
                            undefined
                        );
                        const backendService = this._injector.get(
                            BackendService
                        );
                        if (tokenService) {
                            // try to refresh token
                            return tokenService.refreshToken().pipe(
                                catchError(
                                    (refreshError: HttpResponse<any>) => {
                                        // refresh token failed -> redirect to login
                                        backendService.redirectToLogin();
                                        return throwError(refreshError);
                                    }
                                ),
                                mergeMap(() => {
                                    // token was refreshed, try again to process original stream
                                    // update auth headers
                                    req = this._jwtService.applyJWTHeaders(req);
                                    return next.handle(req);
                                })
                            );
                        } else {
                            // token service is not available -> can't refresh token -> redirect to login
                            backendService.redirectToLogin();
                        }
                    }
                }
                return throwError(err);
            })
        );
    }
}
